body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

.rootDiv {
  background-color: #444;
  height: 100%;
}

.mapDiv {
  height: 100%;
}

.info-container {
  z-index: 999;
  /*width: auto;*/
  max-width: 35%;
  max-height: 80%;
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1.35rem;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  position: fixed;
  top: 1rem;
  background-color: #fff;
  border-radius: 0.25rem;
  font-size: 14px;
  color: #666;
  box-shadow: 0 2px 6px 0 rgba(27, 142, 236, 0.5);
  transition: transform 0.3s;
}

@media screen and (max-width: 1000px) {
  .info-container {
    max-width: 50%;
  }
}

@media screen and (max-width: 700px) {
  .info-container {
    max-width: unset;
    width: auto;
    left: 0;
    right: 0;
  }
}

.info-container[data-fold='true'] {
  transform: translateX(-95%);
}

.info[data-fold='true'] {
  visibility: hidden;
}

.left-fold {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
}

.left-fold[data-fold='true'] > svg {
  transform: rotate(180deg);
  transform-origin: center;
}

a.aboutButton {
  display:inline-block;
  padding:0.7em 1.4em;
  margin:0 0.3em 0.3em 1.5em;
  border-radius:0.15em;
  box-sizing: border-box;
  text-decoration:none;
  font-family:'Roboto',sans-serif;
  text-transform:uppercase;
  font-weight:400;
  color:#FFFFFF;
  background-color:#3369ff;
  box-shadow:inset 0 -0.6em 0 -0.35em rgba(0,0,0,0.17);
  text-align:center;
  position:relative;
}