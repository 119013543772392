.source-switch {
  margin-bottom: 5px;
}

.label-col {
    margin-left: 10px;
    display: flex !important;
    align-items: center;
}

.info-list {
  margin-top: 10px;
  margin-right: 25px !important;
  background-color: white;
  overflow-x: hidden;
  overflow-y: auto;
}

.info-list .ant-list-item:hover {
  background: #fafafa;
}

.info-list .ant-list-item.selected-item {
  background: #e6f7ff;
  border-color: #00000008;
}

.info-list .info-item {
  display: block;
  text-decoration: none;
  cursor: pointer;
}

.info-item-content {
   white-space: pre-wrap;      /* CSS3 */
   white-space: -moz-pre-wrap; /* Firefox */
   white-space: -o-pre-wrap;   /* Opera 7 */
   word-wrap: break-word;      /* IE */
}

.info-item:first-child {
  border-top: none;
}

.info-item-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
}

.info-item-link {
  margin-left: 10px;
  /*margin-right: 5px;*/
  font-size: 13px;
}

.ant-btn-link.info-item-link {
  padding: 0 !important;
}

.info-item-date {
  display: inline;
  float: right;
  font-size: 13px;
  color: #666;
}

.info-button-list {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 400px) {
  .info-button-list a {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.info-item-tag-list {
  margin-left: 10px;
}

.info-sheet-item .info-item-tag-list {
  margin-left: 0;
}

.info-list-header {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.info-list-header .info-list-search {
  flex-basis: 100%;
  margin: 0 25px;
}

.info-list-header .info-list-category {
  margin-top: 10px;
  margin-right: 5px;
}

.info-list-header .info-list-types {
  margin-top: 10px;
  min-width: 80px;
}

.info-list-header .info-list-types .ant-select-selection-search {
}

.info-list-search-icon {
  /*color: red;*/
}